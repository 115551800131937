import React, { useState } from 'react';
import '../Header/Header.css';
import Logo from '../../assets/img/logo.png';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';
import Translate from './../../utils/Translate';

const customerCount = 100; 
const customerImages = [
    '/client1.webp',
    '/client2.webp',
    '/client3.webp',
    '/client4.webp',
    '/client5.webp', 
];

const linksColor= {
    color: 'black',
    hover: '#ccc8f6'
}

const Header = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    return (
        <div>
            <header>
                <div className="container-fluid">
                    <Navbar expand="md">
                        <NavbarBrand href="/"><img src={Logo} alt="Logo Addabra"/></NavbarBrand>
                        <NavbarToggler onClick={toggle} />
                        <Collapse isOpen={isOpen} navbar>
                            <Nav className="mr-auto" navbar>
                                <NavItem>
                                    <NavLink style={linksColor} href="#servicios">{Translate.get('services', "Servicios")}</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink style={linksColor} href="#herramientas">{Translate.get('technologies', "Herramientas")}</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink style={linksColor} href="#proceso">{Translate.get('ourProcess', "Nuestro proceso")}</NavLink>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </Navbar>

                    <div className="row rowHeaderText">
                        <div className="col-sm-12 header">
                            <h1 className="title">{Translate.get('developSoftware', "Desarrollamos software")}</h1>
                            <h1 className="title2">{Translate.get('developCustomSoftware', "a la medida.")}</h1>
                            <h2 className="subtitle">{Translate.get('workingUnderstand', "Desde la idea hasta el lanzamiento, nuestro equipo de expertos lo ayudará a crear las soluciones de software personalizadas que su empresa necesita para mantenerse competitivo en el panorama digital actual.")}</h2>
                            <div className="customer-reviews">
                                <div className="customer-images">
                                    {customerImages.map((image, index) => (
                                    <img key={index} src={process.env.PUBLIC_URL + image} alt={`Customer ${index + 1}`} />
                                    ))}
                                    </div>
                                    <div className="rating">
                                    <span className="stars">★★★★★</span> {/* Replace with a star component or images if needed */}
                                    <span className="text text-reviews">from more than {customerCount} happy clients.</span>
                                    </div>
                                    <div className="heart">
                                    <span>❤️</span> {/* This could be an image or an icon from a library like FontAwesome */}
                                </div>
                            </div>
                            <button  onClick={() => {window.location="#contacto"}} className="btn-outline-purple button"> {Translate.get('contact', "CONTACTAR")}</button>
                            {/* <p className="subtitle" style={{fontSize: 20, marginTop: 50}}>Por: EMOR INDUSTRIES AND INVESTMENTS SAS DE CV</p> */}
                            
                        </div>                    
                    </div>
                </div>
            </header>
        </div>
    );
}


export default Header;