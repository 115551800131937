import React from 'react';
import './App.css';
import Header from './components/Header/Header';
import SliderClients from './components/SliderClients/SliderClients';
import Services from './components/Services/Services';
import Tools from './components/Tools/Tools';
import Process from './components/Process/Process';
import FormContact from './components/FormContact/FormContact';
import Footer from './components/Footer/Footer';
import { Component } from 'react';
import Storage from 'localStorage'
import ThankYou from './components/ThankYou/ThankYou';


class ThankYouPage extends Component {
  render() {
    const {match: {path: path}} = this.props;
    let lang = path.replace("/", '');
    
    if ( lang === '' ) {
      var userLang = navigator.language || navigator.userLanguage
      const lang = userLang.split('-')[0] === 'es' ? 'es' : 'en'
      Storage.setItem('lang', lang);
    } else {
      Storage.setItem('lang', 'en');
    }

    return (
      <div className="content">
        <Header />
        {/* <SliderClients /> */}
        {/* <Services /> */}
        <ThankYou />
        {/* <Process /> */}
        {/* <Tools /> */}
        <Footer />
      </div>
    );
  }
  
}

export default ThankYouPage;
