import React from 'react';
import './Tools.css';
import Translate from './../../utils/Translate';


function Tools() {
    return (
        <div id="herramientas" className="toolsContent text-center container-fluid" style={{marginTop: 60}}>
            <h2 className="herramientasTitle">{Translate.get('ourTechnologies', '')}</h2>
            <div className="toolsText">
                <p>{Translate.get('ourTechnologiesDesc', '')}</p>
            </div>
        </div>
    );
}

export default Tools;