import React from 'react';
import './Footer.css';
import LogoAddabra from '../../assets/img/logoAddabra.png';
import Translate from './../../utils/Translate';

function Footer() {
    return (
        <div>
            <a href="https://wa.me/+525544963762?text=Hi%20I%20need%20more%20info" class="float" target="_blank">
                <i class="fa fa-whatsapp my-float"></i>
            </a>
            <footer className="footer">
                <div className="footerimg"></div>
                <div className="container">
                    <img src={LogoAddabra} />
                    <p>Addabra, LLC 2024. {Translate.get('slogan', '')}</p>
                    <p>4730 S FORT APACHE RD STE 300 LAS VEGAS NV 89147</p>
                    <a href="tel:+17029294775">{Translate.get('information', '')}: +1 702 9294775</a>
                    <p>Rimal 3 JBR, Dubai, United Arab Emirates</p>
                    <a href="tel:+971585027311">{Translate.get('information', '')}: +971 58 502 7311</a>
                    <p>RIO MISSISSIPPI #49 PISO 14, CUAUHTEMOC, CUAUHTEMOC CDMX, México</p>
                    <a href="tel:+525544963762">{Translate.get('information', '')}: +971 58 502 7311</a>
                </div>
            </footer>
        </div>
    );
}

export default Footer;