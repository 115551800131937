import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import App from './App'
import ThankYouPage from './ThankYouPage'

/*
 Where:
    module is: account, user, notifications
    section is: faves, seenapse, followers and followees
    or if section have with notifications, then is followed, favorite, seenapsed
 */

export default class Routes extends Component {
  render() {
    return (
        <Switch>
            <Route exact path='/' component={App} />
            <Route exact path='/en' component={App} />
            <Route exact path='/thank-you' component={ThankYouPage} />
        </Switch>
    )
  }
}
