import React from 'react';
import './SliderClients.css';
// import resuelve from '../../assets/img/clientes/resuelve-tu-deuda-logo.jpg';
import seenapse from '../../assets/img/clientes/seenapse.jpg';
import financiero from '../../assets/img/clientes/financiero-logo.jpg';
import claroVideo from '../../assets/img/clientes/claro-video-logo.jpg';
import coppel from '../../assets/img/clientes/coppel-logo.jpg';
import infinitum from '../../assets/img/clientes/infinitum-logo.jpg';
import exitus from '../../assets/img/clientes/exitus-credit-logo.jpg';
import aclara from '../../assets/img/clientes/aclara-logo.png';
import ibero from '../../assets/img/clientes/ibero-logo.png';
import youtube from '../../assets/img/clientes/yotube-logo.png';
import semco from '../../assets/img/clientes/semco.png';
import att from '../../assets/img/clientes/att-logo.png';
import Translate from './../../utils/Translate';

function SliderClients() {
    return (
        <div className="contentClients">
            <h2 className="text-center titleClients">{Translate.get('weTrust', "Ellos confían en nosotros")}</h2>
            <div className="container-fluid">
                <div className="row">
                    <div className="clientSectionMobile">
                        <div className="image-clients-big">
                            <div className="imagesClients">
                                <img src={financiero} alt="El financiero Addabra"/>
                            </div>
                        </div>
                        <div className="image-clients-big">
                            <div className="imagesClients">
                                <img src={claroVideo} alt="Claro Video Addabra"/>
                            </div>
                        </div>
                        <div className="image-clients-big">
                            <div className="imagesClients">
                                <img src={coppel} alt="Coppel Addabra"/>
                            </div>
                        </div>
                        <div className="image-clients-big">
                            <div className="imagesClients">
                                <img src={infinitum} alt="Infinitum Addabra"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-16">
                    <div className="clientSectionMobile">
                        <div className="image-clients-big">
                            <div className="imagesClients">
                                <img src={exitus} alt="Exitus Addabra"/>
                            </div>
                        </div>
                        <div className="image-clients-big">
                            <div className="">
                                <img src={seenapse} alt="Seenapse Addabra"/>
                            </div>
                        </div>
                        <div className="image-clients-big">
                            <div className="imagesClients" alt="Aclara Addabra">
                                <img src={aclara} />
                            </div>
                        </div>
                        <div className="image-clients-big">
                            <div className="imagesClients">
                                <img src={ibero} alt="Ibero Addabra"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-16">
                    <div className="clientSectionMobile">
                        <div className="image-clients-big">
                            <div className="imagesClients">
                                <img src={youtube} alt="Exitus Addabra"/>
                            </div>
                        </div>
                        <div className="image-clients-big">
                            <div className="">
                                <img src={semco} alt="Seenapse Addabra"/>
                            </div>
                        </div>
                        <div className="image-clients-big">
                            <div className="imagesClients" alt="Aclara Addabra">
                                <img src={att} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SliderClients;