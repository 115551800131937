import firebase from 'firebase';

var firebaseConfig = {
    apiKey: "AIzaSyDP0KHQZAISrHRKYWF5gLj5ph2caLqQzFY",
    authDomain: "addabra-web.firebaseapp.com",
    databaseURL: "https://addabra-web.firebaseio.com",
    projectId: "addabra-web",
    storageBucket: "addabra-web.appspot.com",
    messagingSenderId: "187959747214",
    appId: "1:187959747214:web:ce0f94de8e5f10a19b9fdf",
    measurementId: "G-L7YFGB8GTS"
  };
  // Initialize Firebase
  const fbse = firebase.initializeApp(firebaseConfig);

export default fbse;