import React, { Component } from 'react';
import './ThankYou.css';
import {
    InputGroup,
    InputGroupAddon,
    Button,
    Input,
    FormGroup, 
    Form,
    FormText, 
    Label
} from 'reactstrap';

import fbse from '../../fireBaseCloud';
import Translate from '../../utils/Translate';


class ThankYou extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form: [],
            email: '',
            name: '',
            phone: '',
            description: '',
            hidden: false,
            ok: false
        };
    }

    render() {
        return (
            <div id="contacto" className="container">
                <div style={{marginTop: 40}}>
                    <h3 className="formTitle" style={{fontSize: 40, marginTop: 180, marginBottom: 120}}>{Translate.get('thankYou', '')}</h3>
                </div>
            </div>
        );
    }
}

export default ThankYou;