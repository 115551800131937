import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'

import './index.css';
// import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';

// import { hydrate, render } from "react-dom";
import Routes from './Routes'


// const rootElement = document.getElementById("root");

// if (rootElement.hasChildNodes()) {
//   hydrate(<React.StrictMode> <App /> </React.StrictMode>, rootElement);
// } else {
//   render(<React.StrictMode> <App /> </React.StrictMode>, rootElement);
// }

ReactDOM.render(
  <BrowserRouter>
    <Routes />
  </BrowserRouter>,
  document.getElementById('root')
)


// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
