import React, { Component } from 'react';
import './FormContact.css';
import {
    InputGroup,
    InputGroupAddon,
    Button,
    Input,
    FormGroup, 
    Form,
    FormText, 
    Label
} from 'reactstrap';

import fbse from '../../fireBaseCloud';
import Translate from './../../utils/Translate';


class FormContact extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form: [],
            email: '',
            name: '',
            phone: '',
            description: '',
            hidden: false,
            ok: false
        };
    }

    resetForm() {
      this.setState({email: '', name: '', phone: ''})
    }

    onChange(field, value) {
      this.setState({[field]: value})
    }

    sendMessage(e) {
        e.preventDefault();
        const { 
            email, 
            name, 
            phone, 
            description, 
            country, 
            developmentType, 
            developmentTime, 
            projectStatus, 
            businessSize 
        } = this.state;
        const params = {
            email: email,
            name: name,
            phone: phone,
            country: country,
            description: description,
            company_id: 1,
            url: window.location.href,
            origin: "landing",
            date: Date(),
            developmentType: developmentType,
            developmentTime: developmentTime,
            projectStatus: projectStatus,
            businessSize: businessSize
        };

        if (email && developmentType && developmentTime && projectStatus && businessSize) {
            
            fbse.database().ref('/form').push(params).then(() => {  })
            .catch((e) => {})
            .finally(() => {
                const url = 'https://massivex.addabra.com/api/v1/customer';
                fetch(url, {
                method: 'POST',
                body: JSON.stringify({customer: params}),
                headers:{
                    'Content-Type': 'application/json'
                }
                }).then(res => console.log(res))
                .catch(error => console.error('Error:', error))
                .then(response => console.log('Success:', response));

                this.resetForm();
                this.setState({ok: true, hidden: true});
                
                window.gtag('event', 'conversion', {email: email});
                window.gtag('event', 'conversion', {
                    'send_to': 'AW-586592317/vW1lCLTRld8BEL3g2pcC'
                });

                window.gtag('event', 'conversion', {email: email});
                window.gtag('event', 'conversion', {
                    'send_to': 'AW-586592317/J8BdCNS9v5sYEL3g2pcC'
                });  

                window.location = "/thank-you"
                setTimeout(() => {
                    this.setState({ ok: false });
                }, 20000)
            });
        } else {
            alert('Por favor llena los campos requeridos');
        };
    }

    render() {

        const {email, name, phone, description, country, ok, hidden} = this.state;
        return (
            <div id="contacto" className="container">
                {this.state.alert && <div className={`alert alert-${this.state.alertData.type}`} role='alert' >
                        <div className='container'>
                            {this.state.alertData.message}
                        </div>
                </div>}
                {!hidden && <form ref='contactForm'>
                    <div className="sectionFormContact" >
                        <h3 className="formTitle">
                            {Translate.get('doYouHaveAProject', "¿Tienes un proyecto en mente ó necesitas una cotización?")} <br/> 
                            {Translate.get('leaveYourContact', "")} <br/> 
                            {Translate.get('consultantCall', "")} 
                        </h3>
                        <Form>
                            <FormGroup>
                              <Input id='name' className="inputContact" placeholder={Translate.get('nameFirstName', '')}  type="text" onChange={e => this.onChange('name', e.target.value)} value={name} />
                            </FormGroup>

                            <FormGroup>
                              <Input id='email' className="inputContact" placeholder={Translate.get('exampleEmail', '')} type="email" onChange={e => this.onChange('email', e.target.value)} value={email} />
                            </FormGroup>

                            <FormGroup>
                              <Input id='phone' className="inputContact" placeholder={Translate.get('mobile', '')} type="text" onChange={e => this.onChange('phone', e.target.value)} value={phone} />
                            </FormGroup>

                            <FormGroup>
                              <Input id='country' className="inputContact" placeholder={Translate.get('country', '')} type="text" onChange={e => this.onChange('country', e.target.value)} value={country} />
                            </FormGroup>

                            <FormGroup>
                                <Label for="developmentType">{Translate.get('whatTypeDevelopment', '')}</Label>
                                <Input type="select" className="inputContact" name="developmentType" onChange={e => this.onChange('developmentType', e.target.value)} required>
                                    <option></option>
                                    <option>{Translate.get('apps', '')}</option>
                                    <option>{Translate.get('webAndApps', '')}</option>
                                    <option>{Translate.get('saasDevelopment', '')}</option>
                                    <option>{Translate.get('webPage', '')}</option>
                                    <option>{Translate.get('uxui', '')}</option>
                                    <option>{Translate.get('enhacement', '')}</option>
                                    <option>{Translate.get('consultancy', '')}</option>
                                </Input>
                            </FormGroup>

                            <FormGroup>
                                <Label for="projectStatus">{Translate.get('projectStatus', '')}</Label>
                                <Input type="select" className="inputContact" name="projectStatus" onChange={e => this.onChange('projectStatus', e.target.value)} required>
                                    <option></option>
                                    <option>{Translate.get('iHaveIdea', '')}</option>
                                    <option>{Translate.get('iHaveMVP', '')}</option>
                                    <option>{Translate.get('isWorking', '')}</option>
                                </Input>
                            </FormGroup>

                            <FormGroup>
                               <Label for="businessSize">{Translate.get('howBigIsYourBusiness', '')}</Label>
                               <Input type="select" className="inputContact" name="businessSize" onChange={e => this.onChange('businessSize', e.target.value)} required>
                                    <option></option>
                                    <option>{Translate.get('sizeBusiness', '')}</option>
                                    <option>{Translate.get('startup', '')}</option>
                                    <option>{Translate.get('llc', '')}</option>
                                    <option>{Translate.get('corporation', '')}</option>
                                </Input>
                            </FormGroup>

                            <FormGroup>
                                <Label for="developmentTime">{Translate.get('timeDoYouNeed', '')}</Label>
                                <Input type="select" className="inputContact" name="developmentTime" onChange={e => this.onChange('developmentTime', e.target.value)} required>
                                    <option></option>
                                    <option>{Translate.get('days', '')}</option>
                                    <option>{Translate.get('weeks', '')}</option>
                                    <option>{Translate.get('months', '')}</option>
                                </Input>
                            </FormGroup>

                            <FormGroup>
                               <Label for="description">{Translate.get('describeProject', '')}</Label>
                                <Input id='text' type="textarea" className="inputContact" placeholder="" onChange={e => this.onChange('description', e.target.value)} value={description} />
                            </FormGroup>

                            <FormGroup>
                                <Button color="primary-purpple" onClick={this.sendMessage.bind(this)}>{Translate.get('sent', '')}</Button>
                            </FormGroup>
                        </Form>
                        
                    </div>
                </form>}
                <div>
        
                {/* <div className="calendly-inline-widget" data-url="https://calendly.com/addabra/30min" style={{minWidth:30, height:630}}></div> */}

                </div>
                {ok && <div>
                    <h3 className="formTitle" style={{fontSize: 40, marginTop: 120, marginBottom: 120}}>¡Gracias! 🙌 En breve te contactaremos. 😊 </h3>
                
                </div>}
            </div>
        );
    }
}

export default FormContact;