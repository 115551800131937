import React from 'react';
import './Process.css';

import imgEmpatizar from '../../assets/img/empatizar.png';
import imgDefinir from '../../assets/img/definir.png';
import imgIdear from '../../assets/img/idear.png';
import imgDesarrollar from '../../assets/img/desarrollar.png';
import imgMedir from '../../assets/img/medir.png';
import Translate from './../../utils/Translate';

function Process() {
    return (
        <div id="proceso" className="text-center container-fluid" style={{marginTop: 172}}>
            <h2 className="titleProcess">{Translate.get('ourProcess', '')}</h2>
            <div className="row rowContentProcess">
                <div className="horizontal">
                    <div className="col-sm-2">
                        <div className="contentProcess">
                            <img className="imageProcess" src={imgEmpatizar} />
                            <div className="textProcess">{Translate.get('empathize', '')}</div>
                        </div>
                    </div>
                    <div className="col-sm-2">
                        <div className="contentProcess">
                            <img className="imageProcess" src={imgDefinir} />
                            <div className="textProcess">{Translate.get('define', '')}</div>
                        </div>
                    </div>
                    <div className="col-sm-2">
                        <div className="contentProcess">
                            <img className="imageProcess" src={imgIdear} />
                            <div className="textProcess">{Translate.get('ideate', '')}</div>
                        </div>
                    </div>
                    <div className="col-sm-2">
                        <div className="contentProcess">
                            <img className="imageProcess" src={imgDesarrollar} />
                            <div className="textProcess">{Translate.get('develop', '')}</div>
                        </div>
                    </div>
                    <div className="col-sm-2">
                        <div className="contentProcess">
                            <img className="imageProcess" src={imgMedir} />
                            <div className="textProcess">{Translate.get('measure', '')}</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Process;